/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// UI lib component
import { Container, Row } from 'react-grid-system';

// UI local components
import InputIcon from '../../../helpers/input_with_Icon';

// Assets
import searchIcon from '../../../images/index/search-section/recherche.png';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                             Search Section                                 */
/* -------------------------------------------------------------------------- */

function SearchSection() {
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="search-section flex flex-column items-center justify-center text-center max-w fluid">
      <h1>
        Recherchez des partenaires
        <br />
        pour développer des nouveaux projets
      </h1>
      <Row className="search-box fluid">
        <InputIcon
          image={searchIcon}
          alt="search icon"
          type="text"
          placeholder="Recherche"
        />
      </Row>
    </Container>
  );
}

export default SearchSection;
