/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// UI lib component
import { Container, Row } from 'react-grid-system';

// UI local component
import SectionTitle from '../section-title';
import CallProjectItem from '../single-call-project';

// Fake APIS
import Project from '../../../shared/fake-APIs/projects';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                  Projects Section Of Index Page                            */
/* -------------------------------------------------------------------------- */

function ProjectsSection() {
  /* -------------------------------- HELPERS ------------------------------- */
  const projects = Project?.map((project) => {
    return <CallProjectItem project={project} />;
  });

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="projects-section max-w fluid">
      <Container className="section-title-container">
        <SectionTitle title="Appels à projets" />
      </Container>
      <Container className="max-w fluid">
        <Row className="fluid">{projects}</Row>
      </Container>
    </Container>
  );
}

export default ProjectsSection;
