/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import RightArrow from '../../../images/index/banner-section/right-arrow.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PrimaryButton({ title, navigation, theme }) {
  /* ******************************** LocalVaribles ******************************* */
  let Class;
  /* --------------------------------- helpers -------------------------------- */
  if (theme === 'white') {
    Class = 'PrimaryButton-white';
  } else if (theme === 'default') {
    Class = 'PrimaryButton-default';
  } else {
    Class = 'PrimaryButton';
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <button
      className={Class}
      type="submit"
      onClick={() => navigate(navigation)}
    >
      {title}
      <img
        className="see_more-btn__img"
        src={RightArrow}
        alt="Right-Arrow"
        aria-hidden="true"
      />
    </button>
  );
}

PrimaryButton.propTypes = {
  title: PropTypes.string.isRequired,
  navigation: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

export default PrimaryButton;
