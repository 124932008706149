// Assets
import IMAGE_1 from '../../images/index/projects-section/image1.webp';
import IMAGE_2 from '../../images/index/projects-section/image2.webp';
import IMAGE_3 from '../../images/index/projects-section/image3.webp';
import IMAGE_4 from '../../images/index/projects-section/image4.webp';

const Project = [
  {
    id: 1,
    image: IMAGE_1,
    content: 'Excellence scientifique',
    project_link: '/cluster/1',
    rank: 1,
    class: true,
  },
  {
    id: 2,
    image: IMAGE_2,
    content: 'Problématiques mondiales et compétivité industrielle européenne',
    project_link: '/cluster/4',
    rank: 2,
    class: true,
  },
  {
    id: 3,
    image: IMAGE_3,
    content: 'Europe innovante',
    project_link: '/cluster/10',
    style_type: 'flex-reverse',
    rank: 3,
    class: true,
  },
  {
    id: 4,
    image: IMAGE_4,
    content:
      "Elargir la participation et renforcer l'espace européen de la recherche",
    project_link: '/cluster/12',
    style_type: 'flex-reverse',
    rank: 4,
    class: true,
  },
];
export default Project;
