/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// UI lib component
import { Container } from 'react-grid-system';

// UI local component
import SectionTitle from '../section-title';
import Calendar from '../CalendarDashbaord';

import EventsComponent from './events-component';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                  Projects Section Of Index Page                            */
/* -------------------------------------------------------------------------- */

function EventsSectionIndex() {
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="events-section max-w fluid">
      <Container className="section-title-container">
        <SectionTitle title="Événements" />
      </Container>
      <EventsComponent />
      <hr />
      <Container className="event-box">
        <Calendar showAllEvents />
      </Container>
    </Container>
  );
}

export default EventsSectionIndex;
