/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// UI lib component
import { Container } from 'react-grid-system';
import PrimaryButton from '../../../shared/UIKit/PrimaryButton';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                  Banner Section Of Index Page                              */
/* -------------------------------------------------------------------------- */

function BannerSectionIndex() {
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="index-banner-section max-w fluid">
      <Container className="index-banner-container flex flex-column justify-center">
        <h1>Horizon Europe</h1>
        <h4>
          Supporter l&apos;excellence scientifique tunisienne à travers le
          programme européen pour la recherche et l&apos;innovation
        </h4>
        <PrimaryButton
          title="En savoir Plus"
          navigation="/about-us"
          theme="white"
        />
      </Container>
    </Container>
  );
}

export default BannerSectionIndex;
