/* eslint-disable max-len */
import Icon1 from '../../images/index/finance-section/financeIcon1.svg';
import Icon2 from '../../images/index/finance-section/financeIcon2.svg';

const Finance = [
  {
    id: 1,
    content:
      "les programmes de travail présentent les secteurs de recherche et d'innovation qui seront financés.Ils sont accessibles par le biais du portail des participants (http://bit.ly/H2020PP) et indiquant le calendrier des appels à proposition à venir. Dès qu'il est arrété chaque appel d'offres donne des informations plus précises sur les questions en matière de recherche et d'innovation auxquelles les condidats à un financement doivent répondre dans leurs propositions",
  },
  {
    id: 2,
    range: 1,
    title: 'Soumettre une proposition',
    content:
      "Les propositions doivent etre soumises avant la date limite de l'appel d'offre concerné. Le portail des participants fournit des instructions claires. Les systeme est simplifié au maximum : plus aucun papier!",
    icon: Icon1,
  },

  {
    id: 3,
    range: 2,
    title: 'Trouver des partenaires',
    content:
      "Les appels d'offres impliquent souvent une équipe constitué d'au moins trois partenaires. La fonction recherche de partenaires> du portail des participants",
    icon: Icon2,
  },
];
export default Finance;
