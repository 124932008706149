/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib component
import { Col, Container, useScreenClass } from 'react-grid-system';

import PrimaryButton from '../../../shared/UIKit/PrimaryButton';

// Gatsby Link

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                 Single Project                             */
/* -------------------------------------------------------------------------- */

function CallProjectItem({ project }) {
  /* ------------------------------- CONST --------------------------------- */
  const screenClass = useScreenClass();
  const IS_MOBILE = ['xs', 'sm', 'md'].includes(screenClass);
  const classAttribute = project.class !== true ? '-white' : '';

  /* ******************************** RENDERING ******************************* */
  return (
    <Col
      className={`fluid call-project-background${classAttribute}`}
      key={project.id}
      xxl={6}
      xl={6}
      lg={6}
      md={12}
      sm={12}
      xs={12}
    >
      <div
        className={` ${IS_MOBILE ? 'flex-column' : ''}
      ${
        !IS_MOBILE && project.style_type ? 'flex-reverse' : ''
      } flex fluid call-project-container${classAttribute}`}
      >
        <Container className="call-project-container__image fluid">
          <img src={project.image} alt={project.content} />
        </Container>
        <Container
          className={`call-project-container${classAttribute}__content flex flex-column justify-center  fluid`}
        >
          <h2>{project.content}</h2>

          <PrimaryButton
            title="En savoir plus "
            navigation={project.project_link}
          />
        </Container>
      </div>
    </Col>
  );
}

CallProjectItem.propTypes = {
  project: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CallProjectItem;
