/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { graphql } from 'gatsby';

// UI local component
import { Container } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSectionIndex from '../page-components/page-index/Banner-section';
import ProjectsSection from '../page-components/page-index/Projects-section';
import FinanceSectionIndex from '../page-components/page-index/financial-section';
import ActualitiesSectionIndex from '../page-components/page-index/actualities-section';
import EventsSectionIndex from '../page-components/page-index/events-section';
import SearchSection from '../page-components/page-index/search-section';

// Style
import '../page-styles/index.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function IndexPage() {
  /* ------------------------------- CONSTANSTS ----------------------------- */
  const SITE_TITLE = 'Horizon Europe Tunisia';
  // eslint-disable-next-line max-len
  const SITE_DESCRIPTION = `L’Association entre la Tunisie et l’Union Européenne a permis d’accéder au statut de pays associé
  et aux opportunités du Programme Européen Recherche et Innovation Horizon 2020`;

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={SITE_TITLE} description={SITE_DESCRIPTION} />
      <Container className="page-index max-w fluid">
        <BannerSectionIndex />
        <ProjectsSection />
        <FinanceSectionIndex />
        <ActualitiesSectionIndex />
        <EventsSectionIndex />
        <SearchSection />
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
