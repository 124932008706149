/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Style
import '../page-components/page-Actualities-components/index.scss';
import './FormMenu.scss';
import { navigate } from 'gatsby';
// Search API
import { searchApi } from '../shared/fake-APIs/search-api';

/* -------------------------------------------------------------------------- */
/*                       Input With Right Inner Icon                          */
/* -------------------------------------------------------------------------- */

function InputIcon({ image, alt, type, placeholder }) {
  /* ******************************** HOOKS ******************************* */
  const [input, setInput] = useState('');
  /* ******************************** HELPERS & CALLBACKS  ******************************* */
  const HandleSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      navigate(searchApi(input));
    }
  };
  /* ******************************** RENDERING ******************************* */
  return (
    <div className="right-inner-icons input-container">
      <img src={image} alt={alt} />
      <input
        className="form-control"
        type={type}
        placeholder={placeholder}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={HandleSubmit}
      />
    </div>
  );
}

InputIcon.propTypes = {
  alt: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default InputIcon;
