/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// UI lib component
import { Container, Row, Col } from 'react-grid-system';

// UI local component
import SectionTitle from '../section-title';

// Fake APIS
import Finance from '../../../shared/fake-APIs/finances';

// Style
import './index.scss';
import '../../../shared/styles/global.scss';
/* -------------------------------------------------------------------------- */
/*                  Projects Section Of Index Page                            */
/* -------------------------------------------------------------------------- */

function FinanceSectionIndex() {
  /* -------------------------------- HELPERS ------------------------------- */
  const Finances = Finance.map((finance) => {
    if (finance.id === 1) {
      return (
        <Col
          xxl={4}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          className="pl-3 fluid"
          key={finance.id}
        >
          <Container className="first-index-finance-wrapper">
            <SectionTitle title="Comment se faire financer ?" />
            <p>{finance.content}</p>
          </Container>
        </Col>
      );
    }
    return (
      <Col
        xxl={4}
        xl={4}
        lg={4}
        md={4}
        sm={12}
        xs={12}
        className="pl-3 fluid"
        key={finance.id}
      >
        <Container className="index-finance-wrapper fluid">
          <Container className="index-finance-container flex flex-column fluid">
            <div className="range-container">
              <h1>{finance.range}</h1>
            </div>
            <img src={finance.icon} alt="Finance-icon" className="icon" />
            <h2>{finance.title}</h2>
            <p>{finance.content}</p>
          </Container>
        </Container>
      </Col>
    );
  });

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="finances-section max-w">
      <Container className="index-finance-box max-w">
        <Row className="fluid">{Finances}</Row>
      </Container>
    </Container>
  );
}

export default FinanceSectionIndex;
